import React from 'react';
import {  
    Route } from 'react-router-dom'


const PublicRoute = (props) =>{ 
    const {component: Component } = props
    
    return (
            <Route 
                {...props}
                render={(renderProps) => (
                    <Component {...renderProps}/> 
                )}
             />
    )
}
export default PublicRoute;