import React, { useEffect } from "react";
import { useMutation, useQuery } from '../../gql'
import { CURRENT_USER_QUERY } from "../../gql/queries";
import Auth from "../../actions/Auth";
import LoginLayout from "../Layouts/LoginLayout";
import {  CircularProgress, AppBar, Tabs, Tab, Box } from "@material-ui/core";
import EditProfile from "../../components/Dashboard/EditProfile";
import ChangePassword from "../../components/Dashboard/ChangePassword";
import { RESET_PASSWORD_MUTATION, UPDATE_USER_MUTATION } from "../../gql/mutations";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default (props) => {
    const [changePassword, { data: PasswordData }] = useMutation(RESET_PASSWORD_MUTATION);
    const [updateUser, { data: userDate }] = useMutation(UPDATE_USER_MUTATION);
    const { loading, error, data } = useQuery(CURRENT_USER_QUERY, {
        variables: {
            id: Auth.user().id
        }
    })
    const [value, setValue] = React.useState('general');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (loading) return <LoginLayout><CircularProgress /></LoginLayout>

    return <LoginLayout>
        <AppBar position="static">
            <Tabs value={value} onChange={handleChange} aria-label="wrapped label tabs example">
                <Tab
                    value="general"
                    label="Profil Details"
                />
                <Tab value="password" label="Passwort ändern" />
            </Tabs>
        </AppBar>
        <TabPanel value={value} index="general">
            <EditProfile userId={Auth.user().id} userData={data.users_by_pk} updateUser={updateUser} />
        </TabPanel>
        <TabPanel value={value} index="password">
            <ChangePassword userId={Auth.user().id} changePassword={changePassword} />
        </TabPanel>
    </LoginLayout>
};
