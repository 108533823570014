import Axios from 'axios';
import { isExpired } from "react-jwt";


const tokenExpired = () => {
    const isMyTokenExpired = isExpired(localStorage.getItem('hasuraToken'));

    return isMyTokenExpired;
}

class hasuraToken {

    async checkToken() {
        if (localStorage.getItem('hasuraToken') === null || tokenExpired()) {

            let LoginOptions = {
                method: 'POST',
                url: 'https://service.abiagenten.de/tokenizer',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {}
            };

            const loginRes = await Axios(LoginOptions)

            localStorage.setItem('hasuraToken', loginRes.data.token)
        }
    }
}

export default new hasuraToken();
