import React, {useEffect, useState} from "react";
import { withFormik, ErrorMessage } from "formik";
import * as Yup from "yup";
import bcryptjs from 'bcryptjs';
import { Paper, Grid, Card, CardHeader, CardContent, CardActions } from "@material-ui/core";


function ChangePassword({ handleSubmit, handleChange, values, isValidating,  isSubmitting, errors }) {

    return (
        <>
        <Grid 
            container 
            direction="row"
            justify="center"
            alignItems="center">
            <Grid item sm={10} md={8} lg={6}>
                <Paper elevation={3}>
                <form className="baseForm" onSubmit={handleSubmit} noValidate>
                    {/* Form Header */}
                    <Card>
                        <CardHeader
                            title='Update Password'
                        />
                    <CardContent>
                    {/* Form main content */}
                        <div className="formFieldWrap">
                            <label className="" htmlFor="password">
                                Neues Passwort
                                
                            </label>
                            <div className="formFieldWrapInner">
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    className="password formField"
                                    value={values.password}
                                    onChange={handleChange}
                                />
                            </div>
                            <span className="passwordCue">Mindestens 8 Zeichen lang</span>
                            <ErrorMessage name="password">
                                {errMsg => <span className="errorMessage">{errMsg}</span>}
                            </ErrorMessage>
                        </div>
                        <div className="formFieldWrap">
                        <label className="" htmlFor="password">
                            Wiederholung Passwort
                        
                        </label>
                        <div className="formFieldWrapInner">
                            <input
                                type="password"
                                name="confirm_password"
                                id="confirm_password"
                                className="confirm_password formField"
                                value={values.confirm_password}
                                onChange={handleChange}
                            />
                        </div>
                        {/* <span className="passwordCue">Minimum length is 8 characters</span> */}
                        <ErrorMessage name="confirm_password">
                            {errMsg => <span className="errorMessage">{errMsg}</span>}
                        </ErrorMessage>
                    </div>
                    {errors.server && <span className="errorMessage">{errors.server}</span>}
                    {errors.success && <span className="successMessage">{errors.success}</span>}
                    </CardContent>
                    <CardActions>
                        <div className="formRow col-1">
                            <ErrorMessage name="server">
                                {errMsg => <span className="errorMessage">{errMsg}</span>}
                            </ErrorMessage>
                            
                            <button type="submit">{ isValidating ||  isSubmitting ? 'Lade...' : 'Change Password'}</button>
                        </div>
                    </CardActions>
                    </Card>
                </form>
                </Paper>
            </Grid>
        </Grid>
        </>
    );
}

export default withFormik({
    mapPropsToValues: () => ({
        password: "",
        confirm_password: "",
    }),
    validationSchema: Yup.object().shape({
        password: Yup.string()
            .min(8, "Muss mindestens 8 Zeichen lang sein")
            .required("Passwort ist erforderlich"),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwort stimmt nicht überein')
            .required('Wiederholung ist erforderlich')
    }),

    handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
        const data = {
            id: props.userId,
            password: await bcryptjs.hash(values.password, 12)
        }
        
        // delete data['password'];
        delete data['confirm_password'];
        try {
            setSubmitting(true);

            const {data: resData} = await props.changePassword({variables: data})
            if(resData.update_users){
                setFieldError('success', 'Passwort wurde zurückgesetzt')
            }
            setSubmitting(false);

        } catch (error) {
            setSubmitting(false);
            setFieldError('server', error.message)
            console.log(error, {error})
        }
        setSubmitting(false);
    }

})(ChangePassword);

