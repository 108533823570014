const config = {
    apiKey: "AIzaSyAIzd-UIH_vDEt3R68k06n57L9D-K1w-iQ",
    authDomain: "abi-jackpot.firebaseapp.com",
    databaseURL: "https://abi-jackpot.firebaseio.com",
    projectId: "abi-jackpot",
    storageBucket: "abi-jackpot.appspot.com",
    messagingSenderId: "337648396306",
    appId: "1:337648396306:web:1c3b3d1f884d2579e3d0cb",
    measurementId: "G-J3NDLGD8J8"
}

export default config;