import React from "react"; 

class Auth extends React.Component {
    constructor(props, context) {
        super(props, context);
        // this.user = {};
    }

    async logout() {
        await localStorage.setItem("authData", null);
        window.location.href = '/login';
    }

    checkLogin() {
        const authData = localStorage.getItem('authData')
        if(JSON.parse(authData)){
            const {auth_key, id} = JSON.parse(authData);
    
            if(auth_key && id){
                return true
            }else{
                return false
            }
        }else{
            return false
        }
    }
    user(){
        const authData = localStorage.getItem('authData')
        const {auth_key, id} = JSON.parse(authData);
        return { auth_key, id }
    }  
}

export default new Auth();
