import React, { Fragment } from 'react'
import Auth from '../../actions/Auth'
import {Link} from 'react-router-dom'
import { CssBaseline, AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Button } from '@material-ui/core'
import { AccountCircle, Menu as MenuIcon } from '@material-ui/icons'

export default (props) => {

    const handleLogout = async () => {
        await Auth.logout();
        props.history.push('/login?status=success&message=Logout successfully!')
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);

        // const handleChange = (event) => {
        //     // setAuth(event.target.checked);
        // };

        const handleMenu = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleLogin = () => {
            window.location.href = '/login';
        };

        const handleSignup = () => {
            window.location.href = '/sign-up';
        };

    return (
        <Fragment >
            <CssBaseline />
                <AppBar position="static">
                    <Toolbar style={{
                        display: 'flex',
                        justifyContent: "space-between",
                        backgroundColor: "#000000"
                    }}>
                    {/* <CameraIcon className={classes.icon} /> */}
                    <img src={process.env.PUBLIC_URL + '/logo_abiwallet-01.png'} className={'logo'}/>
                    <Typography variant="h6" color="primary" noWrap>
                        abi.wallet
                    </Typography>
                    
                { Auth.checkLogin() ?
                    <div>
                     <IconButton
                       aria-label="account of current user"
                       aria-controls="menu-appbar"
                       aria-haspopup="true"
                       onClick={handleMenu}
                       color="inherit"
                     >
                       <AccountCircle color="primary"/>
                     </IconButton>
                     <Menu
                       id="menu-appbar"
                       anchorEl={anchorEl}
                       anchorOrigin={{
                         vertical: 'top',
                         horizontal: 'right',
                       }}
                       keepMounted
                       transformOrigin={{
                         vertical: 'top',
                         horizontal: 'right',
                       }}
                       open={open}
                       onClose={handleClose}
                     >
                       <MenuItem><Link to='/dashboard'>Dashboard</Link></MenuItem>
                       <MenuItem><Link to='/account/profile'>Profil</Link></MenuItem>
                       <MenuItem onClick={handleLogout}>Abmelden</MenuItem>
                     </Menu>
                   </div>
                    :
                    <div>
                        <IconButton
                            aria-label="login signup"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <MenuIcon color="primary"/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleLogin}>Anmelden</MenuItem>
                            <MenuItem onClick={handleSignup}>Registrieren</MenuItem>
                        </Menu>
                    </div>

                }
                </Toolbar>
            </AppBar>
        </Fragment>
    )
}