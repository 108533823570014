import React from 'react';
import {  
    BrowserRouter,
    Switch,
    Redirect
} from 'react-router-dom'
import { createBrowserHistory } from 'history';
import {ThemeProvider, createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles'

import Provider from './gql/provider'

import Login from "./modules/Auth/Login";
import SignUp from './modules/Auth/SignUp';
import Activate from './modules/Auth/Activate';
import Dashboard from './modules/Dashboard';
import registerInfo from './modules/Auth/RegisterInfo'
import ForgotPassword from './modules/Auth/ForgotPassword'
import { client } from './gql';
import ResetPassword from './modules/Auth/ResetPassword';
import Profile from './modules/Dashboard/Profile';
import PhoneLogin from './modules/Auth/PhoneLogin';

import PublicRoute from './routes/PublicRoutes';
import PrivateRoute from './routes/PrivateRoutes';

import hasuraToken from './modules/Auth/hasuraToken';

let theme = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            main: "#ffffff"
        },
        secondary: {
            main: "#ffc600"
        }
    },
    appBar: {
        height: 70
    },
    typography: {
        fontFamily: "impact"
    }
});

hasuraToken.checkToken();

theme = responsiveFontSizes(theme)

const hist = createBrowserHistory()

const App = () => (
    <Provider client={client}>
        <ThemeProvider theme={theme}>
            <BrowserRouter history={hist}>
                    <Switch>
                        <Redirect exact from='/' to='/login' />
                        <PublicRoute exact path='/login' component={Login} />
                        <PublicRoute exact path='/phoneLogin' component={PhoneLogin} />
                        <PublicRoute exact path='/sign-up' component={SignUp} />
                        <PublicRoute exact path='/account/activation/:user_id' component={Activate} />
                        <PublicRoute exact path='/register-info' component={registerInfo} />
                        <PublicRoute exact path='/forgot-password' component={ForgotPassword} />
                        <PublicRoute exact path='/account/reset-password/:user_id' component={ResetPassword} />

                        <PrivateRoute exact path='/dashboard' component={Dashboard} />
                        <PrivateRoute exact path='/account/profile' component={Profile} />
                     </Switch>
            </BrowserRouter>
        </ThemeProvider>
    </Provider>
)

export default App;