import React, { useEffect } from 'react';
import {  
    Route, 
    Redirect,
    Router} from 'react-router-dom'
import Auth from '../actions/Auth';


const PrivateRoute = (props) =>{ 
    const {component: Component } = props
    useEffect(() => {
        if(!Auth.checkLogin()){
            return Auth.logout()
        }
    }, [])
    
    return (
        <Route 
            {...props}
            render={(renderProps) => (
                Auth.checkLogin() ?
                <Component {...renderProps}/> :
                <Redirect to='/login?status=error&message=Please login!' />
            )}
        />

    )
}
export default PrivateRoute;