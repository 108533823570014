import React, { useState, useEffect } from "react";
import bcryptjs from 'bcryptjs'
import { USER_LOGIN, GET_USER_BY_EMAIL } from "../../gql/queries";
import FormikApp from './LoginForm'
import { useLazyQuery } from "@apollo/client";
import Auth from "../../actions/Auth";
import LogoutLayout from "../Layouts/LogoutLayout";

export default  (props) => {
    const [userData, setUserData] = useState({}) // all the data
    const [userEmail, setUserEmail] = useState("") // user email
    const [loginData, setLoginData] = useState({}) // login data
    const [status, setStatus] = useState({
        type: "",
        message: ""
    }) // login data

    const [getUserEmail, { data: emailResData, loading: emailResLoading,  error: emailResError, called: emailCalled, refetch: emailRefetch }] = useLazyQuery(GET_USER_BY_EMAIL, {
        onCompleted: data => {
            console.log('User email fetching')
            if(data.users.length !== 0){
                    handleEmailResponse(data.users[0])
                }else{
                    return setStatus({ type: 'error', message: 'Email nicht gefunden!'})
                }
            },
            onError: err => {
                console.log('login err', {err})
                return setStatus({ type: 'error', message: err.message})
            },
    })

    const [loginUser, { data: loginResData, error, loading, called, refetch }] = useLazyQuery(USER_LOGIN,{
        onCompleted: data => {
            console.log('Fetching user login')
            if(data.users.length !== 0 && data.users[0].auth_key){
                handleLoginResponse(data.users[0])
            }else{
                setStatus({ type: 'error', message: 'Passwort ist nicht korrekt!'})
            }
        },
        onError: err => {
            console.log('login err', {err})
            return setStatus({ type: 'error', message: err.message})
        },
        // fetchPolicy: 'no-cache'
    })

    const handleEmailResponse = data => {
        console.log('email', data.password_hash, userData.password)
        if(data.password_hash && userData.password){
            bcryptjs.compare(userData.password, data.password_hash).then(async function(res, err) {
                if(res){
                    reqLoginUser({ email: userEmail, password_hash: data.password_hash})
                    console.log('Requesting user login')
                }else{
                    return setStatus({ type: 'error', message: 'Passwort ist nicht korrekt!'})
                }
            });
        }
    }

    const handleLoginResponse = (data) => {
        const {id, auth_key} = data
        localStorage.setItem('authData', JSON.stringify({id, auth_key }))
        setStatus({
            type: 'success',
            message: 'Anmedung erfolgreich'
        });
        console.log('User login')
        window.location.href = '/dashboard'
    }

    const handleLoginUser = async ( inputData ) => {
        setUserData(inputData)
        setUserEmail(inputData.email)
        if(emailCalled){
            const res = await emailRefetch({email: inputData.email})
            console.log(res.data.users[0])
            if(res.data.users.length !== 0){
                handleEmailResponse(res.data.users[0])
            }else{
                return setStatus({ type: 'error', message: 'Email nicht gefunden!'})
            }
        }else{
            await getUserEmail({ variables: {email: inputData.email}})
        }
    }

    const reqLoginUser = async ({email, password_hash}) => {
        if(!called){
            await loginUser({variables: { email, password_hash }})
        }else{
            console.log('refeching the data')
            const res = await refetch({ email, password_hash })
            if(res.data.users.length !== 0 && res.data.users[0].auth_key){
                handleLoginResponse(res.data.users[0])
            }else{
                setStatus({ type: 'error', message: 'Password is incorrect hai!'})
            }
        }
    }

    useEffect(() => {
        if(error || emailResError ){
            console.log({error, emailResError})
            setStatus({
                type: 'error',
                message: "error.message || emailResError.message"
            })
        }else if(loading || emailResLoading){
            setStatus({
                type: 'loading',
                message: 'Lade'
            })
        }
    }, [error, loading, emailResError, emailResLoading])

    useEffect(() => {
        if(Auth.checkLogin()){
            props.history.push('/dashboard')
        }
        const paramsObj = {}
        const params = new URLSearchParams(window.location.search);
        for (const [key, value] of params) {
              paramsObj[key] = value
        }
        const { status, message } = paramsObj
        if(paramsObj){
            setStatus({ type: status, message})
        }
    }, [])
    
    return <LogoutLayout>
            <FormikApp loginUser={handleLoginUser} responseStatus={status} />
        </LogoutLayout>
};
