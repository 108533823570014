import React from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
      width: '100%',
      justifyContent: 'center'
    },
  }));
  
export default (props) => {
    const classes = useStyles()
    return (
        <div className={classes.root}> <CircularProgress /></div>
    )
}