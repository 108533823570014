import React from 'react'
import client from './client'
import { ApolloProvider } from '@apollo/client';

const Provider = ({ children }) => (
    <ApolloProvider client={client}>
        {children}
    </ApolloProvider>
);

export default Provider