import React, { useEffect, useState } from 'react'
import LoginLayout from '../Layouts/LoginLayout';
import {
    Card,
    CardContent,
    Typography,
    Button,
} from '@material-ui/core';

const handleClick = () => {
    window.location.href = '/login?message=Der Account wurde erfolgreich angelegt.&status=success'
}

export default(props) => {

    return(
        <LoginLayout>
            <div style={{minHeight:600, width:"auto", backgroundColor: "#000000"}}>
            <Card style={{textAlign:"center", backgroundColor: "#000000", paddingTop: 100}}>
                <CardContent>
                    <Typography variant='h3'>
                        Account wurde erstellt.
                    </Typography>
                    <br /> <br />
                    <Typography >
                        Der Account wurde erstellt, eine Email zur Verifizierung wurde an die angegeben Email Adresse versendet.
                        <br />
                        Nach der Bestätigung des Aktivierungslinks in der Email ist die Anmeldung freigesschaltet.
                    </Typography>
                    <br /> <br /> <br />
                    <Button variant="outlined" color="secondary" onClick={handleClick}>Zur Anmeldung</Button>
                </CardContent>
            </Card>
            </div>
        </LoginLayout>
    )
}