import React, { useEffect, useState } from 'react'
import { useMutation } from '../../gql'
import { ACTIVATE_USER } from '../../gql/mutations'
import { Link } from 'react-router-dom'
import LogoutLayout from '../Layouts/LogoutLayout'
import Loader from '../../components/Common/Loader';
import {Button, Card, CardContent, Typography} from "@material-ui/core";
import LoginLayout from "../Layouts/LoginLayout";

export default (props) => {
    // const [userId, setUserId] = useState(null)
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const [activateUser, { data, loading, error: mutationErr }] = useMutation(ACTIVATE_USER);

    const handleClick = () => {
        window.location.href = '/login?message=Der Account wurde erfolgreich aktiviert.&status=success'
    }

    useEffect(() => {
        if(props.match.params.user_id){
            // setUserId(props.match.params.user_id)
            handleUserActivation(props.match.params.user_id)
        }else{
            setError('No user found!')
        }
    }, [])

    useEffect(() => {
        if(!!mutationErr){
            setError(mutationErr.message)
        }
    }, [mutationErr])

    const handleUserActivation = async (id) => {
        try {
            const res = await activateUser({variables: {id}})
            if(res.data.update_users.returning.length !== 0){
                setMessage('Account erfolgreich aktiviert!')
            }else{
                setError("Der Benutzer existiert nicht")
            }
            console.log(res)
        } catch (error) {
            setError(error.message)
            // console.log({error})
        }
    }

    return (
        <LoginLayout>
            <div style={{minHeight:600, width:"auto", backgroundColor: "#000000"}}>
                <Card style={{textAlign:"center", backgroundColor: "#000000", paddingTop: 100}}>
                    <CardContent>
                        <Typography variant='h3'>
                            Account ist aktiviert.
                        </Typography>
                        <br /> <br />
                        <Typography >
                            Der Account ist aktiviert und kann nun verwendet werden.
                        </Typography>
                        <br /> <br /> <br />
                        <Button variant="outlined" color="secondary" onClick={handleClick}>Zur Anmeldung</Button>
                    </CardContent>
                </Card>
            </div>
        </LoginLayout>
    )
}
