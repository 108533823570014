import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import config from '../../config/firebase';
import LogoutLayout from "../Layouts/LogoutLayout";
import { useQuery } from "@apollo/client";
import { GET_USER_BY_UID } from "../../gql/queries";
import { CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

firebase.initializeApp(config);
// firebase.auth().languageCode = 'de';

let unregisterAuthObserver;
const PhoneLogin = () => {
    const [isSignedIn, setSignedIn] = useState(false)
    const [userId, setUserId] = useState(false)
    const [alert, setAlert] = useState({
        show: false,
        type: 'error',
        message: ''
    })
    
    const { loading, error, data } = useQuery(GET_USER_BY_UID, {
        variables: {
            uid: userId
        },
        skip: !userId,
        onCompleted: (data) => {
            if(data.users){
                handleLoginRequest(data)
            }
        },
        onError: err => {
            console.log('login err', {err})
            return setAlert({show: true, type: 'error', message: err.message})
        },
    })
    useEffect(() => {
        unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => setSignedIn(!!user))
    }, [])

    useEffect(() => {
        unregisterAuthObserver();
    }, [unregisterAuthObserver])

    useEffect(() => {
        if(data){
            handleLoginRequest(data)
        }
    }, [data])

    const handleLoginRequest = (data) => {
        if(data.users.length === 0){
            firebase.auth().signOut()
            setAlert({
                show: true,
                type: 'error',
                message: 'User not found!'
            });
        }else{
            handleLoginResponse(data.users[0])
        }
    }

    const handleLoginResponse = (data) => {
        const {id, auth_id} = data
        localStorage.setItem('authData', JSON.stringify({id, auth_key: auth_id }))
        setAlert({
            show: true,
            type: 'success',
            message: 'Anmedung erfolgreich'
        });
        console.log('User login')
        window.location.href = '/dashboard'
    }
    // () {
    //     // const fbase = firebase.initializeApp(config);
    //     //   var ui = new firebaseui.auth.AuthUI(firebase.auth());
    //     //   ui.start("#firebaseui-auth-container", uiConfig);

    // }

    // componentWillUnmount() {
    //     this.unregisterAuthObserver();
    // }

    const uiConfig = {
        signInSuccessUrl: "https://wallet.abiagenten.de/dashboard", //This URL is used to return to that page when we got success response for phone authentication.
        signInOptions: [
            {
                provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                defaultCountry: 'DE',
                providerName: 'Abiagenten',
            },
        ],
        callbacks: {
            // signInSuccess: (currentUser, credential, redirectUrl) => {
            //     console.info({currentUser, credential, redirectUrl})
            // },
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                var user = authResult.user;
                console.log({ authResult });
                setUserId(user.uid)
            }
        },
        tosUrl: "https://wallet.abiagenten.de/dashboard"
    };

    const renderForm = () => {
        if (!isSignedIn) {
            return (
                <div>
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                </div>
            );
        }
        return (
            <div>
                <p>Welcome {firebase.auth().currentUser.displayName}! You are now signed-in!</p>
                <a onClick={() => firebase.auth().signOut()}>Sign-out</a>
            </div>
        );
    }

    return (
        <LogoutLayout>
            <>
                <img src={process.env.PUBLIC_URL + '/logo_abiwallet-01.png'} />
                <div className="formWrapper">
                    {/* Form Header */}
                    <header className="baseFormHeader">
                        <h1 className="baseFormHeading">Anmeldung</h1>
                    </header>
                    {renderForm()}
                    {alert.show && <Alert  variant="outlined" severity={alert.type}>{alert.message}</Alert>}
                    {loading && <CircularProgress />}
                    {error && <div className='errorMessage'>error</div>}
                </div>
                <p>Schon registriert? <Link to='/login'>Anmelden</Link> </p>
            </>
        </LogoutLayout>
    )
}

export default PhoneLogin;