import { gql } from './index';

export const CURRENT_USER_QUERY = gql`
    query CurrentUserById($id: uuid!) {
        users_by_pk(id: $id) {
            id
            nickname
            gender
            name
            email
            mobile_number
            birthday
            graduates_in
        }
    }
`;

export const GET_USER_BY_EMAIL = gql`
    query GetUserByEmail ($email: String!) {
        users(where: {email: {_eq: $email}}){
        id
        password_hash
    } 
    }
`;
export const GET_USER_BY_NICKNAME = gql`
    query GetUserByNickname ($nickname: String!) {
        users(where: {nickname: {_eq: $nickname}}){
            id
            password_hash
        } 
    }
`;
export const GET_USER_BY_MOBILE_NUMBER = gql`
    query GetUserByMobile ($mobile_number: String!) {
        users(where: {mobile_number: {_eq: $mobile_number}}){
            id
            password_hash
        }
    }
`;

export const GET_USER_BY_UID = gql`
    query getUserByUID($uid: String!) {
        users(where: {auth_id: {_eq: $uid}}) {
            id
            auth_id
        }
    }
`;


export const USER_LOGIN = gql`
    query userLogin($email: String!, $password_hash: String!) {
        users(where: {email: {_eq: $email}, _and: {password_hash: {_eq: $password_hash}}}) {
            id
            auth_key
        }
    }
`;

export const GET_WALLET = gql`
    query getWallet($id: uuid!) {
        wallet_wallet(where: {assignee_id: {_eq: $id}}) {
            assignee_id
            value
        }
    }
`;

export const GET_WALLET_TRANSACTIONS = gql `
    query getWalletTransactions($user: uuid!) {
        wallet_transactions(where: {user: {_eq: $user}}) {
            value
            transaction_type
            timestamp
            campaign {
                description
            }
        }
    }
`;