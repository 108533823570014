import React, {useEffect, useState} from "react";
import { withFormik, ErrorMessage } from "formik";
import * as Yup from "yup";
import bcryptjs from 'bcryptjs';
import { Link } from "react-router-dom";

import { useMutation } from '../../gql'
import {  RESET_PASSWORD_MUTATION } from "../../gql/mutations";
import Auth from "../../actions/Auth";
import LogoutLayout from "../Layouts/LogoutLayout";;


function ResetPassword({ handleSubmit, handleChange, values, isValidating,  isSubmitting, errors, responseStatus }) {

    return (
        <>
            <div className="formWrapper">
                <form className="baseForm" onSubmit={handleSubmit} noValidate>
                    {/* Form Header */}
                    <header className="baseFormHeader">
                        <h1 className="baseFormHeading">Passwort zurücksetzen</h1>
                    </header>
                    {/* Form main content */}
                    {responseStatus.type === 'form' && responseStatus.status && 
                        <>
                           <div className="formFieldWrap">
                                <label className="formFieldLabel" htmlFor="password">
                                   Neues Passwort
                                    
                                </label>
                                <div className="formFieldWrapInner">
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        className="password formField"
                                        value={values.password}
                                        onChange={handleChange}
                                    />
                                </div>
                                <span className="passwordCue">Mindestens 8 Zeichen lang</span>
                                <ErrorMessage name="password">
                                    {errMsg => <span className="errorMessage">{errMsg}</span>}
                                </ErrorMessage>
                            </div>
                            <div className="formFieldWrap">
                            <label className="formFieldLabel" htmlFor="password">
                                Wiederholung Passwort
                            
                            </label>
                            <div className="formFieldWrapInner">
                                <input
                                    type="password"
                                    name="confirm_password"
                                    id="confirm_password"
                                    className="confirm_password formField"
                                    value={values.confirm_password}
                                    onChange={handleChange}
                                />
                            </div>
                            {/* <span className="passwordCue">Minimum length is 8 characters</span> */}
                            <ErrorMessage name="confirm_password">
                                {errMsg => <span className="errorMessage">{errMsg}</span>}
                            </ErrorMessage>
                        </div>
                        </>
                     }
                   { responseStatus.type === 'form' && !responseStatus.status && <span className="errorMessage">{responseStatus.message}</span>} 
                    {errors.server && <span className="errorMessage">{errors.server}</span>}
                    {errors.success && <span className="successMessage">{errors.success}</span>}
                    <div className="formRow col-1">
                        <ErrorMessage name="server">
                            {errMsg => <span className="errorMessage">{errMsg}</span>}
                        </ErrorMessage>
                        
                        <button type="submit">{ isValidating ||  isSubmitting ? 'Lade...' : 'Passwort zurücksetzen'}</button>
                    </div>
                </form>
            </div>
            <p>Schon registriert? <Link to='/login'>Anmelden</Link> </p>
        </>
    );
}

 const FormikApp = withFormik({
    mapPropsToValues: () => ({
        password: "",
        confirm_password: "",
    }),
    validationSchema: Yup.object().shape({
        password: Yup.string()
            .min(8, "Muss mindestens 8 Zeichen lang sein")
            .required("Passwort ist erforderlich"),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwort stimmt nicht überein')
            .required('Wiederholung ist erforderlich')
    }),

    handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
        const data = {
            id: props.userId,
            password: await bcryptjs.hash(values.password, 12)
        }
        
        // delete data['password'];
        delete data['confirm_password'];
        try {
            setSubmitting(true);

            const {data: resData} = await props.resetPassword({variables: data})
            if(resData.update_users){
                setFieldError('success', 'Passwort wurde zurückgesetzt')
                window.location.href = '/login'
            }
            setSubmitting(false);

        } catch (error) {
            setSubmitting(false);
            setFieldError('server', error.message)
            console.log(error, {error})
        }
        setSubmitting(false);
    }

})(ResetPassword);

export default  (props) => {
    const [resetPassword, { data }] = useMutation(RESET_PASSWORD_MUTATION);
    const [userId, setUserId] = useState(null)
    const [status, setStatus] = useState({})

    useEffect(() => {
        if(props.match.params.user_id && props.match.params.user_id.length > 30){
            setUserId(props.match.params.user_id)
            // handleUserActivation(props.match.params.user_id)
            setStatus({type: 'form', message:'Benutzer nicht gefunden!', status: true})
        }else{
            setStatus({type: 'form', message:'Kein Benutzer gefunden!', status: false})
        }
    }, [])

    // useEffect(() => {
    //     if(Auth.checkLogin()){
    //        props.history.push('/dashboard')
    //     }
    // }, [])
    
    return <LogoutLayout>
            <FormikApp responseStatus={status} userId={userId} resetPassword={resetPassword} />
        </LogoutLayout>
};
