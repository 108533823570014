import React, { Fragment } from 'react'
import {Typography, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    footer: {
      backgroundColor: "black",
      padding: theme.spacing(2)
    },
}));
function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'© '}
        <a color="inherit" href="https://abiagenten.de" target='_blank'>
        abiagenten
        </a>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

export default (props) => {
    const classes = useStyles()
    return (
        <Fragment >
            <footer className={classes.footer}>
                <Typography variant="h6" align="center" gutterBottom>
                abi.wallet
                </Typography>
                <Copyright />
            </footer>
        </Fragment>
    )
}