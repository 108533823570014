import React from "react";
import { withFormik, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import SmartphoneIcon from '@material-ui/icons/Smartphone';

const useStyle = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
}));

function Login(props) {
    const { handleSubmit, handleChange, values, submitCount, isValidating,  isSubmitting, errors, responseStatus } = props
    return (
        <>
            <img src={process.env.PUBLIC_URL + '/logo_abiwallet-01.png'} />
            <div className="formWrapper">
                <form className="baseForm" onSubmit={handleSubmit} noValidate>
                    {/* Form Header */}
                    <header className="baseFormHeader">
                        <h1 className="baseFormHeading">Anmeldung</h1>
                    </header>
                    {/* Form main content */}
                    <div className="formRow col-1">
                        <div className="formFieldWrap">
                            <label className="formFieldLabel" htmlFor="email">
                                Email Adresse
                            </label>

                            <div className="formFieldWrapInner">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="email formField"
                                    value={values.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <ErrorMessage name="email">
                                {errMsg => <span className="errorMessage">{errMsg}</span>}
                            </ErrorMessage>
                        </div>
                    </div>
                    <div className="formFieldWrap">
                        <label className="formFieldLabel" htmlFor="password">
                            Passwort
                        </label>
                        <div className="formFieldWrapInner">
                            <input
                                type="password"
                                name="password"
                                id="password"
                                className="password formField"
                                value={values.password}
                                onChange={handleChange}
                            />
                        </div>
                        <span className="passwordCue">Mindestens 8 Zeichen | <Link to='/forgot-password'>Passwort vergessen?</Link></span>
                        <ErrorMessage name="password">
                            {errMsg => <span className="errorMessage">{errMsg}</span>}
                        </ErrorMessage>
                    </div>
                    {errors.server && <span className="errorMessage">{errors.server}</span>}
                    {responseStatus.type === 'error' && <span className="errorMessage">{responseStatus.message}</span>}
                    {responseStatus.type === 'loading' && <span className="successMessage">Lade...</span>}
                    {responseStatus.type === 'success' && <span className="successMessage">{responseStatus.message}</span>}
                    <div className="formRow col-1">
                        <button type="submit" disabled={isSubmitting}>{ isValidating ||  isSubmitting ? 'Anmeldung...' : 'Anmelden'}</button>
                    </div>
                    <div className="formRow col-1">
                        <span className="passwordCue" align="center" style={{marginBottom: 10 +'px'}}>
                            In der abi.jackpot App registriert?
                        </span>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<SmartphoneIcon />}
                            component={Link}
                            to="/phoneLogin"
                        >
                            Mit Telefonnummer anmelden
                        </Button>
                    </div>
                </form>
            </div>
            <p>

                Noch nicht registriert? <Link to='/sign-up'>Registrierung</Link>
            </p>
        </>
    );
}

export default withFormik({
    mapPropsToValues: () => ({ email: "", password: "" }),
    validationSchema: Yup.object().shape({
        email: Yup.string()
            .email("Ungültige Email Adresse")
            .required("Required"),
        password: Yup.string()
            .min(8, "Das Passwort muss mindestens 8 Zeichen lang sein")
            .required("Required")
    }),

    handleSubmit: async (values, actions) => {
        const { setSubmitting, setFieldError, props, setErrors } = actions
        setSubmitting(true)
        try {
            values.email = values.email.toLowerCase();
            props.loginUser(values)
        } catch (error) {
            setFieldError('server', error.message);
            console.log({error})
        }
        setSubmitting(false);
    }
})(Login);
