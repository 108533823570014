import React, {useEffect} from "react";
import { withFormik, ErrorMessage } from "formik";
import * as Yup from "yup";
import bcryptjs from 'bcryptjs';
import { Link } from "react-router-dom";


import { useMutation } from '../../gql'
import { SIGNUP_MUTATION } from "../../gql/mutations";
import Auth from "../../actions/Auth";
import LogoutLayout from "../Layouts/LogoutLayout";
import Axios from "axios";
import QueryString from "qs";
import RegistrationMail from '../../components/Email/Registration';
import {renderEmail} from 'react-html-email';
import moment from "moment";

const phoneRegEx = /^(((((((00|\+)49[ \-/]?)|0)[1-9][0-9]{1,4})[ \-/]?)|((((00|\+)49\()|\(0)[1-9][0-9]{1,4}\)[ \-/]?))[0-9]{1,7}([ \-/]?[0-9]{1,5})?)$/;


function SignUp({ handleSubmit, handleChange, values, isValidating,  isSubmitting, errors }) {
    return (
        <>
            <div className="formWrapper">
                <form className="baseForm" onSubmit={handleSubmit} noValidate>
                    {/* Form Header */}
                    <header className="baseFormHeader">
                        <h1 className="baseFormHeading">Registrierung</h1>
                    </header>
                    {/* Form main content */}
                    <div className="formRow col-1">
                        <div className="formFieldWrap">
                            <label className="formFieldLabel" htmlFor="gender">
                                Anrede

                            </label>

                            <div className="formFieldWrapInner">
                                <select
                                    name="gender"
                                    id="gender"
                                    className="gender formField"
                                    value={values.gender}
                                    onChange={handleChange}
                                >
                                    <option value="female"> Frau</option>
                                    <option value="male"> Herr</option>
                                    {/* <option value="other">Other</option> */}
                                </select>
                            </div>
                            <ErrorMessage name="gender">
                                {errMsg => <span className="errorMessage">{errMsg}</span>}
                            </ErrorMessage>
                        </div>
                    </div>
                    <div className="formRow col-1">
                        <div className="formFieldWrap">
                            <label className="formFieldLabel" htmlFor="name">
                                Vor- und Nachname
                            </label>

                            <div className="formFieldWrapInner">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="name formField"
                                    value={values.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <ErrorMessage name="name">
                                {errMsg => <span className="errorMessage">{errMsg}</span>}
                            </ErrorMessage>
                        </div>
                    </div>
                    <div className="formRow col-1">
                        <div className="formFieldWrap">
                            <label className="formFieldLabel" htmlFor="nickname">
                                Benutzername
                            </label>
                            <div className="formFieldWrapInner">
                                <input
                                    type="text"
                                    name="nickname"
                                    id="nickname"
                                    className="nickname formField"
                                    value={values.nickname}
                                    onChange={handleChange}
                                />
                            </div>
                            <ErrorMessage name="nickname">
                                {errMsg => <span className="errorMessage">{errMsg}</span>}
                            </ErrorMessage>
                        </div>
                    </div>
                    <div className="formRow col-1">
                        <div className="formFieldWrap">
                            <label className="formFieldLabel" htmlFor="email">
                                Email Adresse
                                
                            </label>

                            <div className="formFieldWrapInner">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="email formField"
                                    value={values.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <ErrorMessage name="email">
                                    {errMsg => <span className="errorMessage">{errMsg}</span>}
                            </ErrorMessage>
                        </div>
                    </div>
                    <div className="formRow col-1">
                        <div className="formFieldWrap">
                            <label className="formFieldLabel" htmlFor="email">
                                Bestätigung Email Adresse

                            </label>

                            <div className="formFieldWrapInner">
                                <input
                                    type="email"
                                    name="confirm_email"
                                    id="confirm_email"
                                    className="confirm_email formField"
                                    value={values.confirm_email}
                                    onChange={handleChange}
                                />
                            </div>
                            <ErrorMessage name="email">
                                {errMsg => <span className="errorMessage">{errMsg}</span>}
                            </ErrorMessage>
                        </div>
                    </div>
                    <div className="formRow col-1">
                        <div className="formFieldWrap">
                            <label className="formFieldLabel" htmlFor="graduates_in">
                               Abschlussjahr
                            </label>

                            <div className="formFieldWrapInner">
                                <select
                                    name="graduates_in"
                                    id="graduates_in"
                                    className="graduates_in formField"
                                    value={values.graduates_in}
                                    onChange={handleChange}
                                >
                                    {/* <option value="2019">2019</option> */}
                                    <option value="">&nbsp;</option>
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                </select>
                            </div>
                            <ErrorMessage name="graduates_in">
                                {errMsg => <span className="errorMessage">{errMsg}</span>}
                            </ErrorMessage>
                        </div>
                    </div>
                    <div className="formRow col-1">
                        <div className="formFieldWrap">
                            <label className="formFieldLabel" htmlFor="mobile_number">
                               Mobilnummer
                            </label>

                            <div className="formFieldWrapInner">
                                <input
                                    type="tel"
                                    name="mobile_number"
                                    id="mobile_number"
                                    className="mobile_number formField"
                                    value={values.mobile_number}
                                    onChange={handleChange}
                                />
                            </div>
                            <ErrorMessage name="mobile_number">
                                {errMsg => <span className="errorMessage">{errMsg}</span>}
                            </ErrorMessage>
                        </div>
                    </div>
                    <div className="formRow col-1">
                        <div className="formFieldWrap">
                            <label className="formFieldLabel" htmlFor="birthday">
                               Geburtsdatum
                                
                            </label>

                            <div className="formFieldWrapInner">
                                <input
                                    type="date"
                                    name="birthday"
                                    id="birthday"
                                    className="birthday formField"
                                    value={values.birthday}
                                    onChange={handleChange}
                                />
                            </div>
                            <ErrorMessage name="birthday">
                                {errMsg => <span className="errorMessage">{errMsg}</span>}
                            </ErrorMessage>
                        </div>
                    </div>
                    <div className="formFieldWrap">
                        <label className="formFieldLabel" htmlFor="password">
                            Passwort
                            
                        </label>
                        <div className="formFieldWrapInner">
                            <input
                                type="password"
                                name="password"
                                id="password"
                                className="password formField"
                                value={values.password}
                                onChange={handleChange}
                            />
                        </div>
                        <span className="passwordCue">Mindestens 8 Zeichen lang</span>
                        <ErrorMessage name="password">
                            {errMsg => <span className="errorMessage">{errMsg}</span>}
                        </ErrorMessage>
                    </div>
                    <div className="formFieldWrap">
                        <label className="formFieldLabel" htmlFor="password">
                            Wiederholung Passwort
                           
                        </label>
                        <div className="formFieldWrapInner">
                            <input
                                type="password"
                                name="confirm_password"
                                id="confirm_password"
                                className="confirm_password formField"
                                value={values.confirm_password}
                                onChange={handleChange}
                            />
                        </div>
                        {/* <span className="passwordCue">Minimum length is 8 characters</span> */}
                        <ErrorMessage name="confirm_password">
                            {errMsg => <span className="errorMessage">{errMsg}</span>}
                        </ErrorMessage>
                    </div>
                    {errors.server && <span className="errorMessage">{errors.server}</span>}
                    <div className="formRow col-1">
                        <ErrorMessage name="server">
                            {errMsg => <span className="errorMessage">{errMsg}</span>}
                        </ErrorMessage>
                        
                        <button type="submit">{ isValidating ||  isSubmitting ? 'Lade...' : 'Registrieren'}</button>
                    </div>
                </form>
            </div>
            <p>Schon registriert? <Link to='/login'>Anmelden</Link> </p>
        </>
    );
}

const now = new Date();

 const FormikApp = withFormik({
    mapPropsToValues: () => ({
        name: "", 
        nickname: "", 
        email: "",
        confirm_email: "",
        password: "",
        confirm_password: "",
        birthday: new Date(now.getFullYear()-17, now.getMonth(), now.getDate()).toISOString().slice(0, 10),
        graduates_in: "2021",
        gender: "female",
        mobile_number: ""
    }),
    validationSchema: Yup.object().shape({
        name: Yup.string("Bitte Namen eintragen.")
            .required("Erforderlich"),
        nickname: Yup.string("Bitte Benutzernamen eintragen")
            .required("Erforderlich"),
        gender: Yup.string("Bitte Anrede wählen")
            .required("Erforderlich"),
        birthday: Yup.string()
            .test(
                "DOB",
                "Das Mindestalter für die Registrierung beträgt 17 Jahre",
                value => {
                return moment().diff(moment(value),'years') >= 17;
                }
            )
            .required("Erforderlich"),
        mobile_number: Yup.string("Bitte Mobilnummer eintragen")
            .matches(phoneRegEx, "Diese Nummer ist nicht gültig"),
        email: Yup.string("Bitte Email Adresse eintragen")
            .email("Ungültige Email Adresse")
            .required("Erforderlich"),
        confirm_email: Yup.string("Bitte Email Adresse wiederholen")
            .email("Ungültige Email Adresse")
            .oneOf([Yup.ref('email'), null], 'Email Adressen stimmen nicht überein')
            .required("Bitte Email Adresse wiederholen"),
        password: Yup.string()
            .min(8, "Muss mindestens 8 Zeichen lang sein")
            .required("Passwort ist erforderlich"),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwort stimmt nicht überein')
            .required('Wiederholung ist erforderlich')
    }),

    handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
        const data = {
            ...values,
           password_hash: await bcryptjs.hash(values.password, 12)
        }

        if(data.mobile_number.trim() == '') {
            data.mobile_number = null;
        }
        data.email = data.email.toLowerCase();

        delete data['password'];
        delete data['confirm_password'];
        delete data['confirm_email'];
        try {
            const {data: resData} = await props.createUser({variables: data})
            console.log({resData})
            let LoginOptions = {
                method: 'POST',
                url: 'https://voucher.abijackpot.de/login',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    password: data.password_hash,
                    username: data.email
                }
            };

            const loginRes = await Axios(LoginOptions)
            console.log(loginRes, data.email)
            if(loginRes.data.success){
                let link = process.env.REACT_APP_PUBLIC_URL+'/account/activation/'+resData.insert_users.returning[0].id
                let email = RegistrationMail(link)
                var options = {
                    method: 'POST',
                    url: 'https://voucher.abijackpot.de/sendmail',
                    headers: {
                    'Authorization': 'Bearer ' + loginRes.data.token,
                    'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: QueryString.stringify({
                            sender: 'Registrierung abi.wallet <noreply@abiagenten.de>',
                            email: data.email,
                            subject: "Email Bestätigung.",
                            body: renderEmail(email)
                    })
                }
                const res = await Axios(options)
                console.log({res})
                window.location.href = '/register-info'
            }
          
        //    console.log(someData)
           
        } catch (error) {
            setSubmitting(false);
            setFieldError('server', error.message)
            console.log(error, {error})
        }
        setSubmitting(false);
    }

})(SignUp);

export default  (props) => {
    const [createUser, { data }] = useMutation(SIGNUP_MUTATION);
    // console.log({data})
    useEffect(() => {
        if(Auth.checkLogin()){
           props.history.push('/dashboard')
        }
    }, [])
    
    return <LogoutLayout>
            <FormikApp createUser={createUser} />
        </LogoutLayout>
};
