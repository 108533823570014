import React, { useEffect, useState } from 'react'
import { Query } from 'react-apollo';
import { client, useMutation } from "../../gql";
import { GET_WALLET, GET_WALLET_TRANSACTIONS } from "../../gql/queries";
import { ACTIVATE_VOUCHER } from "../../gql/mutations";
import LoginLayout from '../Layouts/LoginLayout';
import { Table, 
TableHead, 
TableBody, 
TableRow, 
TableCell, 
Card, 
CardHeader, 
CardContent, 
Typography, 
Paper, 
Dialog, 
Button, 
DialogTitle, 
DialogContent, 
DialogContentText, 
TextField, 
DialogActions,
IconButton,
Grid,
TableContainer} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import Auth from '../../actions/Auth';
import Loader from '../../components/Common/Loader';
import Alert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';


  
const wallet = ({id}) => {
    return(
    <Query query={GET_WALLET} variables={{id}} client={client}>
        {({loading, data, error}) => {
            if(loading) return <Loader />;
            if (error) return `Error! ${error}`;
            return (
                <>Aktuelles Guthaben: {data.wallet_wallet.length !== 0 ? data.wallet_wallet[0].value/100 : 0} €</>
            )
        }}
    </Query>
)}


export default (props) => {
    const [userId, setUserId] = useState('')
    const [code, setCode] = useState('')
    const [status, setStatus] = useState({
        type: '',
        message: ''
    })
    const [infoOpen, setInfoOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = React.useState(false);
    const [dbError, setDbError] = React.useState(false);
    const [activateVoucher, {error, loading, data}] = useMutation(ACTIVATE_VOUCHER)
    
    useEffect(() => {
        if(Auth.checkLogin()){
            const { id } = Auth.user()
            setUserId(id)
        }
    })

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setStatus({})
        setCode('')
        setOpen(false);
        setDbError(false);
    };

    const handleInfoClose = () => {
        setInfoOpen(false);
    }

    const handleActivateVoucher = async () => {
        if(!code){
            return setStatus({
                type: 'error',
                message: 'Please add a code!'
            })
        }
        try {
            const {data, error, loading} = await activateVoucher({variables: {
                code,
                user: userId,
                date: new Date()
            }})
            const { update_wallet_voucher} = data
            if(update_wallet_voucher){
                if(update_wallet_voucher.returning.length !== 0){
                    window.location.reload()
                    handleClose()
                    return setStatus({
                        type: 'success',
                        message: 'Gutschein aktiviert!'
                    })
                }
            }

            return setStatus({
                type: 'error',
                message: 'Ungültiger Code!'
            })
            // console.log({update_wallet_voucher})
            
        } catch (error) {
            console.log(error)
            setDbError(true);
            return setStatus({
                type: 'error',
                message: error.message.replace('Uniqueness violation. ','')
            })
        }
    }

    const transactions = ({user}) => (
        <Query query={GET_WALLET_TRANSACTIONS} variables={{user}} client={client}>
            {({loading, data, error}) => { console.log(data)
                if(loading) return <Loader />;
                if (error) return `Error! ${error}`;

                const handleInfoClick = () => {
                    setInfoOpen(true);
                };

                return (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Datum</TableCell>
                                    <TableCell>Aktion</TableCell>
                                    <TableCell>Betrag</TableCell>
                                    <TableCell>Quelle</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.wallet_transactions.length !== 0 ? <>
                                        {data.wallet_transactions.map((transaction, idx) => (
                                            <TableRow key={idx}>
                                                <TableCell>{moment(transaction.timestamp).format('DD MMM YYYY')}</TableCell>
                                                <TableCell>
                                                    {transaction.transaction_type}
                                                    {transaction.transaction_type.includes('Gutschein') ?
                                                        <IconButton aria-label="info"><InfoIcon color="secondary" onClick={handleInfoClick}/></IconButton>
                                                        :
                                                        "" }
                                                </TableCell>
                                                <TableCell>{transaction.value/100} €</TableCell>
                                                <TableCell>{transaction.campaign.description}</TableCell>
                                            </TableRow>
                                        ))
                                        }
                                    </> :
                                    <TableRow>
                                        <TableCell>
                                            Keine Einträge!
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }}
        </Query>
    );

    return (
        <LoginLayout >
            <Card style={{backgroundColor:"black", minHeight: 600, paddingTop: 100}}>
                <CardContent>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={9}>
                            <Typography gutterBottom variant="h5" component="h2">
                                {wallet({id: userId})}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
                            Gutschein hinzufügen
                            </Button>
                        </Grid>
                    </Grid>
                    <br />
                    <Paper>

                    { transactions({user: userId})}
                    </Paper>
                </CardContent>
            </Card>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Gutschein aktivieren</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Bitte Gutscheincode eingeben.
                </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="code"
                        label="Voucher Code"
                        type="text"
                        fullWidth
                        value={code}
                        onChange={e => {
                            setStatus({
                                type: '',
                                message: ''
                            })
                            setCode(e.target.value)
                        }}
                    />
                {status.type && <Alert  variant="outlined" severity={status.type}>{status.message}</Alert>}
                {loading && <Loader />}
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Abbrechen
                </Button>
                    {!dbError ?
                <Button onClick={handleActivateVoucher} color="secondary">
                    Aktivieren
                </Button>
                        : ""}
                </DialogActions>
            </Dialog>
            <Dialog open={infoOpen} onClose={handleInfoClose} fullScreen={fullScreen}>

                <DialogContent>
                    <DialogContentText>
                        Der Betrag des Gutscheins ist Deinem Wallet gutgeschrieben worden.
                        In Zukunft kannst Du diesen Betrag für Bestellungen bei den abiagenten nutzen, oder den Betrag ganz oder teilweise an eine Stufe übertragen.
                        <br/><br/>
                        Die Übertragung an eine Stufe wird bald in der abi.jackpot App möglich sein.
                        <br/><br/>
                        Solltest Du den Betrag jetzt schon für eine Bestellung nutzen wollen, so kontaktiere bitte Deinen zuständigen abiagenten, er wird sich um alles weitere kümmern.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleInfoClose} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </LoginLayout>
    )
}
