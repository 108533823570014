import React from "react";
import { withFormik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Paper, Grid, Card, CardHeader, CardContent, CardActions, Button } from "@material-ui/core";

const phoneRegEx = /^(((((((00|\+)49[ \-/]?)|0)[1-9][0-9]{1,4})[ \-/]?)|((((00|\+)49\()|\(0)[1-9][0-9]{1,4}\)[ \-/]?))[0-9]{1,7}([ \-/]?[0-9]{1,5})?)$/;

function Profile({ handleSubmit, handleChange, values, isValidating,  isSubmitting, errors }) {
    return (
        <Grid 
            container 
            direction="row"
            justify="center"
            alignItems="center">
            <Grid item sm={10} md={8} lg={6}>
                <Paper elevation={3}>
                    <Card> 
                    <form onSubmit={handleSubmit} noValidate>
                        <CardHeader
                            title='Profil bearbeiten'
                        />
                        <CardContent>
                            {/* Form main content */}
                            <div className="formRow col-1">
                                <div className="formFieldWrap">
                                    <label className="" htmlFor="name">
                                        Vor- und Nachname
                                    </label>

                                    <div className="formFieldWrapInner">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="name formField"
                                            value={values.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <ErrorMessage name="name">
                                        {errMsg => <span className="errorMessage">{errMsg}</span>}
                                    </ErrorMessage>
                                </div>
                            </div>
                            <div className="formRow col-1">
                                <div className="formFieldWrap">
                                    <label className="" htmlFor="nickname">
                                        Benutzername (Nicht änderbar)
                                    </label>
                                    <div className="formFieldWrapInner">
                                        <input
                                            readOnly
                                            disabled="disabled"
                                            type="text"
                                            name="nickname"
                                            id="nickname"
                                            className="nickname formField"
                                            value={values.nickname}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <ErrorMessage name="nickname">
                                        {errMsg => <span className="errorMessage">{errMsg}</span>}
                                    </ErrorMessage>
                                </div>
                            </div>
                            <div className="formRow col-1">
                                <div className="formFieldWrap">
                                    <label className="" htmlFor="email">
                                        Email Adresse
                                        
                                    </label>

                                    <div className="formFieldWrapInner">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="email formField"
                                            value={values.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <ErrorMessage name="email">
                                            {errMsg => <span className="errorMessage">{errMsg}</span>}
                                    </ErrorMessage>
                                </div>
                            </div>
                            <div className="formRow col-1">
                                <div className="formFieldWrap">
                                    <label className="" htmlFor="mobile_number">
                                    Mobilnummer
                                    </label>

                                    <div className="formFieldWrapInner">
                                        <input
                                            type="tel"
                                            name="mobile_number"
                                            id="mobile_number"
                                            className="mobile_number formField"
                                            value={values.mobile_number}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <ErrorMessage name="mobile_number">
                                        {errMsg => <span className="errorMessage">{errMsg}</span>}
                                    </ErrorMessage>
                                </div>
                            </div>
                            <div className="formRow col-1">
                            <div className="formFieldWrap">
                                <label className="" htmlFor="graduates_in">
                                Abschlussjahr
                                </label>

                                <div className="formFieldWrapInner">
                                    <select
                                        name="graduates_in"
                                        id="graduates_in"
                                        className="graduates_in formField"
                                        value={values.graduates_in}
                                        onChange={handleChange}
                                    >
                                        {/* <option value="2019">2019</option> */}
                                        <option value="">&nbsp;</option>
                                        <option value="2021">2021</option>
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                    </select>
                                </div>
                                <ErrorMessage name="graduates_in">
                                    {errMsg => <span className="errorMessage">{errMsg}</span>}
                                </ErrorMessage>
                            </div>
                            </div>
                            <div className="formRow col-1">
                                <div className="formFieldWrap">
                                    <label className="" htmlFor="birthday">
                                    Geburtsdatum
                                        
                                    </label>

                                    <div className="formFieldWrapInner">
                                        <input
                                            type="date"
                                            name="birthday"
                                            id="birthday"
                                            className="birthday formField"
                                            value={values.birthday}
                                            onChange={handleChange}
                                            disabled
                                        />
                                    </div>
                                    <ErrorMessage name="birthday">
                                        {errMsg => <span className="errorMessage">{errMsg}</span>}
                                    </ErrorMessage>
                                </div>
                            </div>
                            {errors.server && <span className="errorMessage">{errors.server}</span>}
                            {errors.success && <span className="successMessage">{errors.success}</span>}
                            <ErrorMessage name="server">
                                {errMsg => <span className="errorMessage">{errMsg}</span>}
                            </ErrorMessage>
                        </CardContent>
                        <CardActions>
                            <button type="submit">{ isValidating ||  isSubmitting ? 'Lade...' : 'Update'}</button>
                        </CardActions>
                        </form>
                    </Card>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default withFormik({
    mapPropsToValues: ({userData}) => ({
        name: userData.name, 
        nickname: userData.nickname, 
        email: userData.email,
        graduates_in: userData.graduates_in,
        birthday: new Date(userData.birthday).toISOString().slice(0, 10),
        mobile_number: userData.mobile_number,
    }),

    validationSchema: Yup.object().shape({
        name: Yup.string("Bitte Namen eintragen.")
            .required("Erforderlich"),
        nickname: Yup.string("Bitte Benutzernamen eintragen")
            .required("Erforderlich"),
        birthday: Yup.date("Bitte Geburtsdatum auswählen")
            .required("Erforderlich"),
        mobile_number: Yup.string("Bitte Mobilnummer eintragen")
            .matches(phoneRegEx, "Diese Nummer ist nicht gültig"),
        email: Yup.string("Bitte Email Adresse eintragen")
            .email("Ungültige Email Adresse")
            .required("Erforderlich")
    }),

    handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {

        const data = {
            ...values,
            id: props.userId
        }
        delete data['birthday']
        delete data['nickname']

        if(data.mobile_number.trim() == '') {
            data.mobile_number = null;
        }

        try {
            setSubmitting(true);
            const {data: resData, loading, error } = await props.updateUser({variables: data})
            if(resData.update_users_by_pk){
                setFieldError('success', 'Profile details have been updated!')
            }
            if(error) setFieldError('server', error)
            if(loading) setSubmitting(true);
            setSubmitting(false);
           
        } catch (error) {
            setSubmitting(false);
            setFieldError('server', error.message)
            console.log(error, {error})
        }
        setSubmitting(false);
    }

})(Profile);
