import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';

const httpLink = createHttpLink({uri: process.env.REACT_APP_BACKEND_URL || 'https://serviceapi.abijackpot.de/v1/graphql'})
const middlewareLink = new ApolloLink((operation, forward) => {
    operation.setContext({
        headers: {
            authorization: 'Bearer ' + localStorage.getItem('hasuraToken')
        }
    });
    return forward(operation);
})

const link = middlewareLink.concat(httpLink);
const cache = new InMemoryCache();

export default new ApolloClient({ cache, link })