import React, {useEffect, useState} from "react";
import { withFormik, ErrorMessage } from "formik";
import * as Yup from "yup";
import bcryptjs from 'bcryptjs';
import { Link } from "react-router-dom";

import { GET_USER_BY_EMAIL } from '../../gql'
// import { ForgotPassword_MUTATION } from "../../gql/mutations";
import Auth from "../../actions/Auth";
import LogoutLayout from "../Layouts/LogoutLayout";
import Axios from "axios";
import QueryString from "qs";
import { useLazyQuery } from "@apollo/client";
import PasswordResetMail from "../../components/Email/PasswordReset";
import {renderEmail} from 'react-html-email';

function ForgotPassword({ handleSubmit, handleChange, values, isValidating,  isSubmitting, errors, requestStatus }) {

    return (
        <>
            <div className="formWrapper">
                <form className="baseForm" onSubmit={handleSubmit} noValidate>
                    {/* Form Header */}
                    <header className="baseFormHeader">
                        <h1 className="baseFormHeading">Passwort zurücksetzen</h1>
                    </header>
                    {/* Form main content */}
                    <div className="formRow col-1">
                        <div className="formFieldWrap">
                            <label className="formFieldLabel" htmlFor="email">
                                Email Adresse
                                
                            </label>

                            <div className="formFieldWrapInner">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="email formField"
                                    value={values.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <ErrorMessage name="email">
                                    {errMsg => <span className="errorMessage">{errMsg}</span>}
                            </ErrorMessage>
                        </div>
                    </div>
                    {/* {errors.server && <span className="errorMessage">{errors.server}</span>} */}
                    {requestStatus.type && <span className={requestStatus.type === 'error' ? "errorMessage" : "successMessage"}>{requestStatus.message}</span>}
                    <div className="formRow col-1">
                        <ErrorMessage name="server">
                            {errMsg => <span className="errorMessage">{errMsg}</span>}
                        </ErrorMessage>
                        
                        <button type="submit">{ isValidating ||  isSubmitting ? 'Sende...' : 'Link senden'}</button>
                    </div>
                </form>
            </div>
            <p>Zurück zur Anmeldung? <Link to='/login'>Anmelden</Link> </p>
        </>
    );
}

 const FormikApp = withFormik({
    mapPropsToValues: () => ({
        email: "",
    }),
    validationSchema: Yup.object().shape({
        email: Yup.string("Bitte Email Adresse eintragen")
            .email("Ungültige Email Adresse")
            .required("Required"),
    }),

    handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
        const data = {
            ...values,
        }
        values.email = values.email.toLowerCase();
        try {
            props.handleEmail(values.email)
            const resData = await props.getUserByEmail({variables: {email: values.email}})

            setSubmitting(props.loading)
            console.log(props.loading, props.data)
           
        } catch (error) {
            setSubmitting(false);
            setFieldError('server', error.message)
            console.log(error, {error})
        }
        setSubmitting(false);
    }

})(ForgotPassword);

export default  (props) => {
    const [email, setEmail] = useState('')
    const [status, setStatus] = useState({})
    const [getUserByEmail, { loading, data, error, called, refetch }] = useLazyQuery(GET_USER_BY_EMAIL,{
        onCompleted: async data => {
            if(data.users.length !== 0 && data.users[0].id){
                try {
                    setStatus({ type: 'server', message: 'Hole User Daten'})
                    var LoginOptions = {
                        method: 'POST',
                        url: 'https://voucher.abijackpot.de/login',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: {password:process.env.REACT_APP_API_PASS, username: process.env.REACT_APP_API_USER}
                    };
    
                    const loginRes = await Axios(LoginOptions)
                    setStatus({ type: 'server', message: 'Sende Email.'})

                    if(loginRes.data.success){
                        let link = process.env.REACT_APP_PUBLIC_URL+'/account/reset-password/'+data.users[0].id
                        let emailBody = PasswordResetMail(link);
                        let options = {
                            method: 'POST',
                            url: 'https://voucher.abijackpot.de/sendmail',
                            headers: {
                            'Authorization': 'Bearer ' + loginRes.data.token,
                            'Content-Type': 'application/x-www-form-urlencoded'
                            },
                                data: QueryString.stringify({
                                        sender: 'Passwort zurücksetzen abi.wallet <noreply@abiagenten.de>',
                                        email: email,
                                        subject: "Passwort zurücksetzen",
                                        body: renderEmail(emailBody)
                                })
                        }
                        const res = await Axios(options)
                        return setStatus({type: 'server', message: 'Ein Link zum Zurücksetzen des Passwortes wurde an die Email Adresse verschickt'})
                    }else{
                        setStatus({ type: 'error', message: 'Der Link konnte nicht gesendet werden'})
                    }
                } catch (error) {
                    setStatus({ type: 'error', message: 'Der Link konnte nicht gesendet werden'})
                }
            }else{
                console.log('User not found')

                setStatus({ type: 'error', message: 'Email Adresse nicht vorhanden'})
            }
        },
        onError: err => {
            console.log('User err', {err})
            return setStatus({ type: 'error', message: err.message})
        },
        // fetchPolicy: 'no-cache'
    })
    // const [createUser, { data }] = useQuery(GET_USER_BY_EMAIL);
    // console.log({data})
    useEffect(() => {
        if(Auth.checkLogin()){
           props.history.push('/dashboard')
        }
    }, [])
    
    return <LogoutLayout>
            <FormikApp requestStatus={status} handleEmail={e => setEmail(e)} loading={loading} error={error} data={data} getUserByEmail={getUserByEmail} />
        </LogoutLayout>
};
