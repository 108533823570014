import React from 'react';
import {  Email, Item, Box, A, Image, Span } from 'react-html-email';
import { theme } from './theme';

const PasswordResetMail = (Link) => (
    <Email style={{backgroundColor: '#000000'}} title='Password zurücksetzen abi.wallet'>
        <Item align={theme.emailHeader.itemAlign} style={theme.emailHeader.item} >
            <Image src='https://wallet.abiagenten.de/logo_abiwallet-01.png' width='344' height='309' />
        </Item>
        <Box align={theme.emailBody.box.align} style={theme.emailBody.box.style}>
        <Item style={theme.emailBody.bodyContent.item} align="center">
            <Span fontSize={theme.emailBody.bodyContent.span.fontSize} style={theme.emailBody.bodyContent.span}>
                <p>
                    Das Zurücksetzen des Passwortes wurde angefordert. Solltest Du das Zurücksetzen nicht angefordert haben, so ignoriere diese Email einfach!
                </p>
                <p>
                    Um Dein Passwort zurückzusetzen, nutze bitte den Link.
                </p>
            </Span>
        </Item>
        <Item style={theme.emailBody.bodyCallout.item} align="center">
            <A style={theme.emailBody.bodyCallout.a} href={Link}>Passwort zurücksetzen</A>
        </Item>
        <Item style={theme.emailBody.bodyFooter.item} align="center">
            <Span color={theme.emailBody.bodyFooter.span.color}>
                ©{" "}
                <A href='https://abiagenten.de/' color={theme.emailBody.bodyFooter.a.color}>
                    abiagenten
                </A>{" "}
                {new Date().getFullYear()}{" "}
            </Span>
        </Item>
        </Box>
        <Item align={theme.emailFooter.itemAlign} style={theme.emailFooter.item} >
            <Span color={theme.emailFooter.span.color}>
                abiagenten event & textil GmbH
                Friedhofstraße 72
                63263 Neu-Isenburg

                Registernummer: HRB 51722
                Registergericht: Amtsgericht Offenbach am Main
                Geschäftsführer: Andreas Altwasser
            </Span>
        </Item>
    </Email>
)

export default PasswordResetMail;