import React, { Fragment } from 'react'
import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default (props) => {
    return (
        <Fragment>
            <div className='app'>
                {props.children }
            </div>
            <Footer /> 
        </Fragment>
    )
}
